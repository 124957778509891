import SideBar from "./SideBar";
import ContactPage from "./ContactPage";
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import sidebarContext from "../Contexts/sideBarContext";
const Appbody = () => {
  const isVisible = useContext(sidebarContext);

  return (
    <sidebarContext.Provider value={isVisible}>
      <div  className={`${isVisible === "true" ? " flex " : ""}`}>
        <div className="flex">
          <SideBar />
          {/* {isOpen ? <SideBar /> : ""} */}
          <Outlet />
        </div>
        <ContactPage />
      </div>
    </sidebarContext.Provider>
  );
};

export default Appbody;
