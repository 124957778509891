import React, { useState } from "react";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";

const ProjectsPage = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="container  mx-auto flex justify-center items-center w-full  ">
      {/*  */}
      <div className="bg-indigo-400">
        {/* <!-- Modal --> */}
        <TEModal show={showModal} setShow={setShowModal}>
          <TEModalDialog>
            <TEModalContent>
              <TEModalHeader>
                {/* <!--Modal title--> */}
                <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                  Project Information
                </h5>
                {/* <!--Close button--> */}
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </TEModalHeader>
              {/* <!--Modal body--> */}
              <TEModalBody
                style={{ minHeight: "300px" }}
                className="flex flex-wrap"
              >
                <div className="mr-20">
                  <hr />
                  <h2>Tech-Stack:</h2>
                  <hr />
                  <ul>
                    <li>React.js</li>
                    <li>Node.js</li>
                    <li>Tailwind CSS</li>
                    <li>Netlify hosting</li>
                  </ul>
                </div>

                <div>
                  <hr />
                  <h2>Features:</h2>
                  <hr />
                  <ul>
                    <li>Single Page Application</li>
                    <li>Dark Mode</li>
                    <li>Top loading bar</li>
                    <li>Search Functionality</li>
                    <li>Search Suggestions on typing</li>
                    <li>Personalized Suggestions</li>
                    <li>Fully responsive</li>
                  </ul>
                </div>
                <div className="mr-20">
                  <hr />
                  <h2>Tools:</h2>
                  <hr />
                  <ul>
                    <li>Vs Code</li>
                    <li>Netlify Hosting</li>
                    <li>Render Hosting</li>
                    <li>Postman</li>
                    <li>Third party API</li>
                  </ul>
                </div>
              </TEModalBody>
              <TEModalFooter>
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </TERipple>
              </TEModalFooter>
            </TEModalContent>
          </TEModalDialog>
        </TEModal>
      </div>
      {/*  */}
      <div className="flex flex-col items-center w-full my-10">
        <hr className="w-full border-t-2 border-indigo-200 mb-2" />
        <p className="text-3xl font-semibold mb-2">Proof of work</p>
        <hr className="w-full border-t-2 border-indigo-200 mb-4" />
        <h2 className="tracking-wider text-xs font-medium text-gray-400 pt-4 indent-10">
          "Embark on a journey of innovation with my Web Dev Portfolio app,
          meticulously developed using the dynamic prowess of React.js and the
          principles of modern design. This interactive platform is a showcase
          of my web development capabilities, featuring a diverse collection of
          projects, each meticulously built from the ground up. Dive into a
          fusion of code and creativity as you navigate through my portfolio,
          gaining firsthand experience of the seamless synergy between React.js
          and thoughtful design that creates a visually captivating and engaging
          digital exhibition."
        </h2>
        <section className="text-gray-600 body-font text-sm">
          <div className="container px-5 py-5 mx-auto">
            <div className="flex flex-wrap ">
              <div className="p-4 lg:w-1/3 ">
                <div className="h-96 bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative border border-slate-500  ">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-600 mb-1">
                    The News app Samachar
                  </h2>
                  <h2 className="title-font sm:text-2xl text-xl font-medium text-indigo-950 mb-1">
                    Samachar
                  </h2>
                  <p className="leading-relaxed mb-3 text-indigo-950 text-ellipsis overflow-hidden h-52 ">
                    "News app Global News Coverage: Access to a vast array of
                    global news sources. Bharat News Coverage: In-depth
                    reporting on Indian news, culture, and politics.
                    Personalized News Feed: Tailored content based on user
                    preferences. Search Functionality with Debouncing "
                  </p>
                  <div className="flex-col">
                    <div>
                      <a
                        className="text-indigo-500 inline-flex items-center hover:text-red-400"
                        href="https://samachars.netlify.app/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Open Site
                        <svg
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M5 12h14"></path>
                          <path d="M12 5l7 7-7 7"></path>
                        </svg>
                      </a>
                    </div>

                    <span>
                      <button
                        className="text-indigo-500 inline-flex items-center hover:text-red-400"
                        rel="noopener noreferrer"
                        // target="_blank"
                        // onClick={() => setShowModal(false)}
                        onClick={() => setShowModal(true)}
                      >
                        Know more
                        <svg
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M5 12h14"></path>
                          <path d="M12 5l7 7-7 7"></path>
                        </svg>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-4 lg:w-1/3 ">
                <div className="h-96 bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative border border-slate-500  ">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-600 mb-1">
                    e-commerce clone
                  </h2>
                  <h2 className="title-font sm:text-2xl text-xl font-medium text-indigo-950 mb-1">
                    Ecom-clone
                  </h2>
                  <p className="leading-relaxed mb-3 text-indigo-950 text-ellipsis overflow-hidden h-52 ">
                    "Creating an e-commerce website clone app project typically
                    involves replicating the features and functionality of an
                    existing e-commerce platform, often with some
                    customizations."
                    <ul>
                      <li>Cart functionality</li>
                      <li>Product listing</li>
                      <li>Product Filters</li>
                      <li>Context Api</li>
                    </ul>
                  </p>
                  <div className="flex-col">
                    <div>
                      <a
                        className="text-indigo-500 inline-flex items-center hover:text-red-400"
                        href="https://ecomm-clonee.netlify.app/products"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Open Site
                        <svg
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M5 12h14"></path>
                          <path d="M12 5l7 7-7 7"></path>
                        </svg>
                      </a>
                    </div>

                    {/* <span>
                      <button
                        className="text-indigo-500 inline-flex items-center hover:text-red-400"
                        rel="noopener noreferrer"
                        // target="_blank"
                        // onClick={() => setShowModal(false)}
                        onClick={() => setShowModal(true)}
                      >
                        Know more
                        <svg
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M5 12h14"></path>
                          <path d="M12 5l7 7-7 7"></path>
                        </svg>
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
              <div className="p-4 lg:w-1/3">
                <div className="h-96 bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative border border-slate-500 ">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-600 mb-1">
                    Personal portfolio app
                  </h2>
                  <h2 className="title-font sm:text-2xl text-xl font-medium text-indigo-950 mb-1">
                    Mayuresh
                  </h2>
                  <p className="leading-relaxed mb-3 text-indigo-950 text-ellipsis overflow-hidden h-52 ">
                    "Experience innovation at its finest with my Web Dev
                    Portfolio app, meticulously crafted using the dynamic
                    capabilities of React.js and the sleek design principles of
                    Tailwind CSS. This interactive platform showcases my journey
                    in web development, featuring a collection of diverse
                    projects that highlight my proficiency in creating
                    captivating user interfaces and seamless functionality.
                    Explore a fusion of code and creativity as you navigate
                    through my portfolio, experiencing firsthand the synergy
                    between React.js and Tailwind CSS in delivering a visually
                    stunning and engaging digital showcase."
                  </p>
                  <a
                    className="text-indigo-500 inline-flex items-center hover:text-red-400"
                    href="https://mayuresh1007.netlify.app/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Know more
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="p-4 lg:w-1/3 ">
                <div className="h-96 bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative border border-slate-500  ">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-600 mb-1">
                    MakeUp Artist website
                  </h2>
                  <h2 className="title-font sm:text-2xl text-xl font-medium text-indigo-950 mb-1">
                    mua.Akshata
                  </h2>
                  <p className="leading-relaxed mb-3 text-indigo-950 text-ellipsis overflow-hidden h-52 ">
                    "Designed, developed, and launched a dynamic and visually
                    captivating makeup artist website, GlamourVue, to showcase a
                    diverse range of makeup artistry services and foster client
                    engagement. Leveraged a user-centric approach to create an
                    immersive online platform that highlighted the fusion of
                    creativity and technical skill in the realm of beauty
                    enhancement."
                  </p>
                  <a
                    className="text-indigo-500 inline-flex items-center hover:text-red-400"
                    href="https://akshatakumbhar98.github.io/akshatakumbhar/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Know more
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="p-4 lg:w-1/3">
                <div className="h-96 bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative border border-slate-500 ">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-600 mb-1">
                    Basic utility app
                  </h2>
                  <h2 className="title-font sm:text-2xl text-xl font-medium text-indigo-950 mb-1">
                    Todo's
                  </h2>
                  <p className="leading-relaxed mb-3 text-indigo-950 text-ellipsis overflow-hidden h-52">
                    "Todo is a powerful and intuitive todo app designed to help
                    you seamlessly organize your life, boost productivity, and
                    manage tasks with ease. With its sleek and user-friendly
                    interface, TaskMinder empowers you to stay on top of your
                    commitments, prioritize effectively, and achieve your goals,
                    all while eliminating the clutter and stress of daily life."
                  </p>
                  <a
                    className="text-indigo-500 inline-flex items-center hover:text-red-400"
                    href="https://mayuresh1007.github.io/todos/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Know more
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="p-4 lg:w-1/3">
                <div className="h-96 bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative border border-slate-500 ">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-600 mb-1">
                    Video streaming app
                  </h2>
                  <h2 className="title-font sm:text-2xl text-xl font-medium text-indigo-950 mb-1">
                    Youtube clone
                  </h2>
                  <p className="leading-relaxed mb-3 text-indigo-950 text-ellipsis overflow-hidden h-52">
                    "Introducing our ReactTube app, a cutting-edge YouTube clone
                    developed using React.js, powered by the YouTube Public API
                    v3, and enhanced with the sleek styling of Tailwind CSS.
                    With ReactTube, users can dive into an immersive video
                    streaming experience akin to YouTube, exploring an extensive
                    library of content seamlessly fetched from the YouTube API."
                  </p>
                  <a
                    className="text-indigo-500 inline-flex items-center hover:text-red-400"
                    href="https://github.com/mayuresh1007/Youtube-clone-react"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Source code
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="p-4 lg:w-1/3">
                <div className="h-96 bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative border border-slate-500 ">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-600 mb-1">
                    Food ordering app clone
                  </h2>
                  <h2 className="title-font sm:text-2xl text-xl font-medium text-indigo-950 mb-1">
                    Swiggy clone
                  </h2>
                  <p className="leading-relaxed mb-3 text-indigo-950 text-ellipsis overflow-hidden h-52">
                    "Introducing our SwiggyClone app, a sophisticated dining
                    solution built using React.js, enriched by the styling
                    finesse of Tailwind CSS, and seamlessly integrated with the
                    Swiggy Public API. SwiggyClone brings the convenience of
                    online food ordering to users' fingertips, mirroring the
                    functionality and efficiency of the original Swiggy
                    platform.""
                  </p>
                  <a
                    className="text-indigo-500 inline-flex items-center hover:text-red-400"
                    href="https://github.com/mayuresh1007/Swiggy-clone-react"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Source code
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProjectsPage;
