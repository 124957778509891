import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
const Maincontainer = () => {
  const [text] = useTypewriter({
    words: [
      "Web Developer",
      "React.js Developer",
      "Node.js Developer",
      // "Backend Developer",
    ],
    loop: 0,
    onLoopDone: () => console.log(`loop completed after 3 runs.`),
  });
  // mt-12 ml-4
  return (
    <>
      <div className="container  mx-auto flex justify-center items-center lg:w-full md:w-full sm:w-full   pb-2 ">
        <div className="flex flex-wrap lg:flex-row md:flex-col sm:flex-col place-items-center object-fill">
          <div className="    lg:flex-1  ">
            <img
              src="https://avatars.githubusercontent.com/u/77499509?s=400&u=c91141df8296531ac37e16964400c6731e9a6c01&v=4"
              alt=""
              className="rounded-3xl "
            />
          </div>
          <div className="lg:flex-1">
            <div className=" container text-center">
              <h1 className="font-bold text-2xl ">
              Hello, I'm  <span className="text-orange-400">Mayuresh</span>{" "}
              </h1>
              <span className="text-3xl text-orange-300 ">{text}</span>
              <Cursor cursorColor="blue" />
              <p className="mb-1">
                {" "}
                👨🏻‍💼 Passionate Web Developer Creating Digital Experiences
              </p>
            </div>
            <p className="m-auto indent-6">
              Hello, I'm Mayuresh - Your Web Developer ✨ Creating Awesome
              Websites I'm passionate about making fantastic websites. Right
              now, I'm focusing on React and Node.js, two cool tools for
              building websites that work great and look awesome. 👥 Let's Team
              Up I love teaming up on exciting web projects. We can use the
              latest tech and creativity to make something amazing. I'm also
              good at making websites run super fast and keeping up with what's
              new in web design. 📚 Always Learning I'm always learning new
              stuff. Right now, I'm getting even better at React and trying out
              serverless web tech with Node.js. 🤔 Questions? Just Ask If you
              have any questions about websites, React, Node.js, or anything
              else, feel free to ask. I'm here to help. 🌱 Beyond Web Dev When
              I'm not coding, I enjoy exploring different ways of looking at
              life. It's what keeps my creativity flowing.
            </p>
            <span className="">
              <a
                // href={`https://wa.me/${"+918999517710"}`}
                href={`mailto:${"codewithmayuresh@gmail.com"}`}
                target="_blank"
                rel="noreferrer"
              >
                <button className="flex m-2 p-2 border border-indigo-500 rounded-lg hover:bg-indigo-200 mx-auto ">
                  Hire me
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 ml-1 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                    />
                  </svg>
                </button>
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maincontainer;
