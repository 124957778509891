import React from "react";

const SkillCard = ({ iconSrc, title, subtitle }) => (
  <div className="p-2 lg:w-1/3 md:w-1/2 sm:w-full ">
    <div className="h-full flex flex-col items-center border-indigo-300 border p-4 rounded-lg">
      <img
        alt="skill-icon"
        className="w-16 h-16 bg-gray-100 object-cover object-center rounded-full mb-4"
        src={iconSrc}
      />
      <div className="text-center">
        <h2 className="text-lg font-medium">{title}</h2>
        <p className="text-gray-500">{subtitle}</p>
      </div>
    </div>
  </div>
);

const Skills = () => {
  const skillsData = [
    {
      iconSrc:
        "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-1024.png",
      title: "React.js",
      subtitle: "Frontend Development",
    },
    {
      iconSrc:
        "https://miro.medium.com/v2/resize:fit:800/1*4sxOPaVNwxrfZ9uxVbUaKg.jpeg",
      title: "Redux-Toolkit",
      subtitle: "State management for react",
    },
    {
      iconSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGnpI6EvEZqPCteLYyIRa7qlpCxbBOK6xkYSNg3oo0W1CYOQ1QPeTAw6T_KielGBHZFU0&usqp=CAU",
      title: "Node.js",
      subtitle: "Backend Development",
    },
    {
      iconSrc:
        "https://cdn.icon-icons.com/icons2/2699/PNG/512/expressjs_logo_icon_169185.png",
      title: "Express.js",
      subtitle: "Backend Framework",
    },
    {
      iconSrc:
        "https://static.javatpoint.com/images/javascript/javascript_logo.png",
      title: "Javascript",
      subtitle: "Programming Language ",
    },

    {
      iconSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTz2PX_vf0AQpimlMJUhi1fdAiGhJNWIydNwqb3FuquLMZbgUyGWpPd6ADI-TOiUei8oAg&usqp=CAU",
      title: "HTML5",
      subtitle: "Markup language",
    },
    {
      iconSrc:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/800px-CSS3_logo_and_wordmark.svg.png",
      title: "CSS",
      subtitle: "Cascading Style Sheets",
    },
    {
      iconSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmD1vxBivio7fhSYY4alTLJkbUL1zPYNprAA&usqp=CAU",
      title: "SQL",
      subtitle: "Query language",
    },
    {
      iconSrc: "https://www.postgresql.org/media/img/about/press/elephant.png",
      title: "PostgreSQL",
      subtitle: "Database",
    },
    {
      iconSrc: "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png",
      title: "Git",
      subtitle: "Version control",
    },
    {
      iconSrc:
        "https://tailwindcss.com/_next/static/media/social-card-large.a6e71726.jpg",
      title: "Tailwindcss",
      subtitle: "UI Framework",
    },
    {
      iconSrc:
        "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      title: "Bootstrap",
      subtitle: "UI Framework",
    },
  ];

  return (
    <section className=" body-font">
      <div className=" px-5 py-24  mx-auto">
        <div className="text-center mb-10">
          <h2 className="sm:text-3xl text-2xl font-medium title-font mb-4">
            My Skills
          </h2>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            "Much like a coder meticulously crafting code, I've honed my skills
            through unyielding dedication, until experience itself now conducts
            a symphony of mastery, harmonizing expertise and accomplishments."
          </p>
        </div>
        <div className="flex flex-wrap m-2 ">
          {skillsData.map((skill, index) => (
            <SkillCard
              key={index}
              iconSrc={skill.iconSrc}
              title={skill.title}
              subtitle={skill.subtitle}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
