const Education = () => {
  return (
    <div className="container  mx-auto flex justify-center items-center w-full  ">
      <div className="flex flex-col items-center w-full my-10">
        <hr className="w-full border-t-2 border-indigo-200 mb-2" />
        <h1 className="text-3xl font-semibold mb-2">Education</h1>
        <hr className="w-full border-t-2 border-indigo-200 mb-4" />

        <div className="overflow-x-auto">
          <table className="w-full border border-indigo-200 p-3 border-separate border-spacing-1">
            <thead className="bg-indigo-100 text-indigo-950">
              <tr>
                <th className="px-4 py-2 border">Degree</th>
                <th className="px-4 py-2 border">Institution</th>
                <th className="px-4 py-2 border">Year</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2 border sm:w-1/3">
                  Bachelor of Engineering
                </td>
                <td className="px-4 py-2 border sm:w-1/3">
                  Shivaji University ,Kolhapur
                </td>
                <td className="px-4 py-2 border sm:w-1/3">2020</td>
              </tr>
              <tr>
                <td className="px-4 py-2 border sm:w-1/3">H.S.C.</td>
                <td className="px-4 py-2 border sm:w-1/3">
                  Guvuvarya Dadoji Kondadev Sainiki School Tasgaon, India
                </td>
                <td className="px-4 py-2 border sm:w-1/3">2015</td>
              </tr>
              <tr>
                <td className="px-4 py-2 border sm:w-1/3">S.C.C.</td>
                <td className="px-4 py-2 border sm:w-1/3">
                  Guruvarya Dadoji Kondadev Sainiki School Tasgaon, India
                </td>
                <td className="px-4 py-2 border sm:w-1/3">2013</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Education;
