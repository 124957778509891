import SideBar from "./components/SideBar";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Education from "./components/Education";
import Maincontainer from "./components/Maincontainer";
import Appbody from "./components/Appbody";
import ProjectsPage from "./components/ProjectsPage";
import Skills from "./components/Skills";
import ContactPage from "./components/ContactPage";
import Experience from "./components/Experience";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Appbody />,
    children: [
      {
        path: "/",
        element: <Maincontainer />,
      },
      {
        path: "education",
        element: <Education />,
      },
      {
        path: "skills",
        element: <Skills />,
      },
      {
        path: "projects",
        element: <ProjectsPage />,
      },
      {
        path: "contact",
        element: <ContactPage />,
      },
      {
        path: "experience",
        element: <Experience />,
      },
    ],
  },
]);
function App() {
  return (
    <div
      className=" bg-slate-100 text-sky-950
    dark:text-white dark:dark:bg-sky-950"
    >
      <RouterProvider router={appRouter}>
        <SideBar />
        <Appbody />
      </RouterProvider>
    </div>
  );
}

export default App;
