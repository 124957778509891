import React from "react";
const CertificateCard = ({ iconSrc, title, subtitle, srclink }) => (
  <div className="p-2 lg:w-1/3 md:w-1/2 sm:w-full">
    {/* {console.log(iconSrc)} */}
    <div className="h-full flex flex-col items-center border-indigo-300 border p-4 rounded-lg">
      <a href={iconSrc} target="_blank" rel="noopener noreferrer">
        {/* <a href={srclink} target="_blank" rel="noopener noreferrer">put img here</a> */}
        <img
          alt="skill-icon"
          className=" bg-gray-100 object-cover object-center mb-4 cursor-pointer"
          src={iconSrc}
        />
      </a>
      <div className="text-center">
        <h2 className="text-lg font-medium">{title}</h2>
        <p className="text-gray-500">{subtitle}</p>
      </div>
    </div>
  </div>
);

const Experience = () => {
  const CertificateData = [
    {
      iconSrc:
        "https://cutshort.io/_next/image?url=https%3A%2F%2Fcdn.cutshort.io%2Fcertificates%2F79368.jpg&w=1920&q=75",
      title: "Node.js",
      srclink: "https://Cutshort.io/c/79368",
      subtitle: "Cutshort ",
    },
    {
      iconSrc: "/webdev.jpeg",
      title: "Essential program in WEB Development",
      srclink: "https://verify.letsupgrade.in/certificate/LUWEB0221A081",
      subtitle: "LetsUpgrade LUWEB0221A081",
    },
    {
      iconSrc: "/python.jpeg",
      title: "Programming in Python programming",
      srclink: "https://verify.letsupgrade.in/certificate/LUPY0121B081",
      subtitle: "LetsUpgrade LUPY0121B081",
    },
    {
      iconSrc: "/javascript.jpeg",
      title: "Programming in JAVASCRIPT Language",
      srclink: "https://verify.letsupgrade.in/certificate/LUJS0121A301",
      subtitle: "LetsUpgrade LUJS0121A301",
    },
    {
      iconSrc: "/dsa.jpeg",
      title: "Essential program in DATA STRUCTURE AND ALGORITHM",
      srclink: "https://verify.letsupgrade.in/certificate/LUDSA0221A119",
      subtitle: "LetsUpgrade LUDSA0221A119",
    },
  ];
  return (
    <>
      <div className="flex  text-center w-full my-10  ">
        <div className="w-1/2">
          <hr className="w-auto border-t-2 border-indigo-200 mb-2" />
          <h2 className="sm:text-3xl text-2xl font-medium title-font mb-2">
            Experience
          </h2>
          <hr className="w-auto border-t-2 border-indigo-200 mb-4" />
          <div className="">
            <div className="max-w-md mx-auto p-2  rounded-lg shadow-md ">
              <p className="mb-2">
                <span className="font-semibold">Position:</span> Software
                Developer
              </p>
              <p className="mb-2">
                <span className="font-semibold ">Employer:</span> Firstcry.com
              </p>
              <p className="mb-2">
                {/* <span className="font-semibold">Oct 2021 – Present</span> */}
                <span className="text-gray-600 ml-2">Pune, India</span>
              </p>
              {/* <p className="mb-4">
                <span className="font-semibold">Duration:</span> October 2021 -
                Present
              </p> */}
              <hr />
              <h2 className="text-xl font-semibold mb-2">Technical Skills:</h2>
              <hr />
              <ul className="list-disc pl-6 mb-4">
                <li>
                  Proficient in full-stack web development using
                  React.js,JSX,JavaScript, Node.js, Express, PostgreSQL,
                  Bootstrap, HTML5, CSS3.
                </li>
                <li>
                  Experience in implementing RESTful APIs and using Git for
                  version control.
                </li>
              </ul>
              <hr />
              <h2 className="text-xl font-semibold mb-2">
                Key Responsibilities:
              </h2>
              <hr />
              <ul className="list-disc pl-6 mb-4 ">
                <li>
                  Collaborated with cross-functional teams to design, develop,
                  and maintain B2B, B2C, and RVP systems for seamless product
                  delivery.
                </li>
                <li>
                  Integrated courier services such as Ecom and XpressBees to
                  track shipments and ensure timely delivery.
                </li>
                <li>
                  Developed and maintained shipment monitoring systems, and
                  promptly resolved any issues.
                </li>
                <li>
                  Debugged software issues and ensured smooth platform
                  operation.
                </li>
                <li>
                  Wrote clean, well-documented code, and followed best practices
                  for software development to maintain high product quality.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <hr className=" w-auto border-t-2 border-indigo-200 mb-2" />
          <h2 className="sm:text-3xl text-2xl font-medium title-font mb-2">
            Certifications
          </h2>
          <hr className=" border-t-2 border-indigo-200 mb-4" />
          <section className="mt-9 body-font">
            <div className="container mx-auto">
              <div className="flex flex-wrap m-2 ">
                {CertificateData.map((skill, index) => (
                  <CertificateCard
                    key={index}
                    iconSrc={skill.iconSrc}
                    title={skill.title}
                    subtitle={skill.subtitle}
                    srclink={skill.srclink}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Experience;
